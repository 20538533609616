import {
  GATSBY_MENTAL_HEALTH_PROGRAM_CODE,
  GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE,
} from "gatsby-env-variables"

export default [
  {
    name: "Mental Health",
    value: Buffer.from(GATSBY_MENTAL_HEALTH_PROGRAM_CODE),
    img: "brainstorm",
  },
  {
    name: "Physical Health",
    value: Buffer.from(GATSBY_PHYSICAL_HEALTH_PROGRAM_CODE),
    img: "stetho",
  },
]
