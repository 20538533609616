import { useStaticQuery, graphql } from "gatsby"

const useBookingTypeImages = () => {
  return useStaticQuery(graphql`
    {
      brainstorm: file(relativePath: { eq: "brainstorm_1.png" }) {
        childImageSharp {
          fixed(width: 159) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stetho: file(relativePath: { eq: "stethoscope_1.png" }) {
        childImageSharp {
          fixed(width: 156) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
}

export default useBookingTypeImages
