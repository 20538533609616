import React from "react"
import classNames from "classnames"

const Card = ({ title, subtitle, className, children }) => {
  return (
    <div className={classNames("card p-2 is-overflow-hidden", className)}>
      <div className="has-text-centered">
        {title ? (
          <h2
            className={classNames(
              "has-text-centered mb-0 card-heading has-text-primary mx-2-mobile"
            )}
          >
            {title}
          </h2>
        ) : null}
        {subtitle ? (
          <h3 className="card-subheading has-text-grey has-text-centered my-1  mx-1-mobile has-text-weight-normal">
            {subtitle}
          </h3>
        ) : null}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default Card
