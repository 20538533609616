import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"
import Card from "elements/Card"

import styles from "../utils/elements.module.scss"

const FormStyledRadio = ({
  isActive,
  isDisabled,
  onClick,
  label,
  logo,
  children,
  horizontal,
}) =>
  horizontal ? (
    <div onClick={onClick} onKeyDown={onClick} role="button" tabIndex="0">
      <Card
        className={classNames("p-1 mx-1", isDisabled ? styles["disabled"] : "")}
      >
        {logo && <Img fixed={logo} />}
        <div className="is-flex is-align-content-center">
          <div
            className={classNames(styles["styledRadio__radioButton"], "mr-1")}
            style={{ marginTop: "0.3rem" }}
          >
            {isActive && (
              <div
                className={classNames(
                  styles["styledRadio__radioButtonChecked"]
                )}
              ></div>
            )}
          </div>
          <h4 className="has-text-weight-bold">{label}</h4>
        </div>
      </Card>
    </div>
  ) : (
    <div
      className={classNames(
        styles[`styledRadio__option${isActive ? "Active" : ""}`]
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      <div className="columns is-vcentered is-mobile ml-1 ml-0-mobile">
        <div className={classNames(styles["styledRadio__radioButton"])}>
          {isActive && (
            <div
              className={classNames(styles["styledRadio__radioButtonChecked"])}
            ></div>
          )}
        </div>
        <div className="column">
          <div
            className={classNames(
              "is-flex pr-1 is-align-items-center is-flex-wrap mt-1-mobile",
              styles["styledRadio__label"]
            )}
          >
            {logo && <Img fixed={logo} />}
            <div className="column pl-1">
              {label && (
                <h4 className="has-text-weight-bold">{label}</h4>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

export default FormStyledRadio
