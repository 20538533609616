import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Form, Formik } from "formik"

import Layout from "layout"
import Container from "layout/Container"
import Button from "elements/Button"
import Message from "elements/Message"
import FormStyledRadio from "elements/Form/FormStyledRadio"

import useBookingTypeImages from "./hooks/useBookingTypeImages"
import options from "./utils/bookingType"

import { getSignedInUser } from "../../Auth/services/user"

import { ConsultationContext } from "../ConsultationContext/ConsultationContext"

const BookingType = ({ pageContext }) => {
  const { consultationDispatch } = useContext(ConsultationContext)
  const data = useBookingTypeImages()
  const { userData, medstaffData } = getSignedInUser()
  const decodedPermissions =
    userData?.permissions.map((permission) => Buffer(permission).toString()) ||
    []

  const handleSubmit = async (values) => {
    await consultationDispatch({
      type: "SAVE_MEDICAL_HISTORY",
      payload: {
        ...values,
      },
    })

    navigate(
      pageContext.nextPath.replace(
        "{PROGRAM}",
        values?.flow?.name === "Mental Health" ? "mind" : "body"
      )
    )
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.seoTitle}
      pageContext={pageContext}
      isPrivate
    >
      <Container isCentered>
        <h2 className="has-text-primary">
          What would you like to request a teleconsult for?
        </h2>
        <hr />
        {!medstaffData || userData?.permissions.length ? (
          <Message align="left">
            You currently do not have access to these programs. Please consult
            with your HR for assistance.
          </Message>
        ) : null}
        <div className="my-3">
          <Formik
            initialValues={{
              flow: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="is-flex is-flex-wrap-wrap is-justify-content-center">
                  {options.map((option, index) => {
                    const invalid =
                      !decodedPermissions.includes(option.value.toString()) &&
                      !medstaffData
                    return (
                      <FormStyledRadio
                        key={index}
                        isActive={values?.flow === option}
                        isDisabled={invalid}
                        onClick={() => {
                          if (!invalid) setFieldValue("flow", option)
                        }}
                        logo={data[option.img].childImageSharp.fixed}
                        label={option.name}
                        horizontal={true}
                      />
                    )
                  })}
                </div>
                <div className="has-text-centered is-block mt-4">
                  <Button
                    color={"primary"}
                    size={"medium"}
                    isDisabled={values.flow === ""}
                    className={`mt-1 px-4`}
                    type="submit"
                  >
                    Next
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </Layout>
  )
}

export default BookingType
